export const useExclusiveSite = (site) => {
  let label = undefined
  let isExclusive = undefined

  switch (site.slug) {
    case "pornplus":
    case "pornpros":
    case "pornprosnetwork":
      isExclusive = true
  }

  switch (site.slug) {
    case "pornplus":
      label = "PORN+ Exclusive Series"
    default:
      label = `${site.name} Exclusive Series`
  }

  return { label, isExclusive }
}
